import base_actions from '@/store/modules/base/actions'

export default {
    ...base_actions
    ,
    async merge(context, { label_id, merge_label_id, dispatch }) {
        let response = await this._vm.$api[context.state.api_module].merge(label_id, merge_label_id);
        if(response==true) {
            let items = context.state.items.filter(i=>{return i.id !== label_id});
            this._vm.$set(context.state, 'items', items)
            dispatch('snackbars/add_success_snack', 'Merged successfully')
        }
        else {
            dispatch('snackbars/add_failed_snack', 'Failed to merge')
        }
    },    
}